<template>
  <v-card
    outlined
    class="my-4"
  >
    <!-- 有關鍵字搜尋功能 -->
    <v-card-text class="d-flex" v-if="hasSearchFeature">
      <v-text-field
        dense
        single-line
        hide-details
        clearable
        @keyup.enter="search"
        @click:clear="clearSearch"
        :placeholder="searchPlaceholder"
        v-model="keyword"
      ></v-text-field>

      <v-btn
        text
        color="primary"
        @click="search"
      >
        {{'action.start_search'| t}}
      </v-btn>

      <v-btn
        text
        color="primary"
        v-if="hasFilterFeature"
        @click="showFilter = !showFilter"
      >
        {{'list.filter'|t}}
      </v-btn>
    </v-card-text>

    <v-expand-transition v-if="hasSearchFeature && hasFilterFeature">
      <div v-show="showFilter">
        <v-divider></v-divider>
        <listFilter :list-key="listKey"></listFilter>
      </div>
    </v-expand-transition>

    <!-- 無關鍵字搜尋功能 -->
    <div v-if="!hasSearchFeature && hasFilterFeature">
      <listFilter
        :list-key="listKey"
      ></listFilter>
    </div>
  </v-card>
</template>

<script>
export default {
  props: {
    listKey: String,
  },
  data: () => ({
    showFilter: true,
    keyword: null,
  }),
  methods: {
    async clearSearch() {
      await this.$nextTick()
      await this.search()
    },
    async search() {
      if(this.keyword == this.searchKeyword) return
      this.$store.dispatch(`list/${this.listKey}/set`, {
        key: 'searchKeyword',
        value: this.keyword,
      })

      // 從第一頁開始
      this.$store.dispatch(`list/${this.listKey}/set`, {
        key: 'page',
        value: 1,
      })

      this.listActions.changeIndexCondition()
    },
  },
  computed: {
    searchKeyword() {
      return this.$store.getters[`list/${this.listKey}/searchKeyword`]
    },
    listFilter() {
      return this.$store.getters[`list/${this.listKey}/filter`]
    },
    listActions() {
      return this.$store.getters[`list/${this.listKey}/actions`]
    },
    listConfig() {
      return this.$store.getters[`list/${this.listKey}/config`]
    },
    hasSearchFeature() {
      if(!this.listConfig) return false
      return !!this.listConfig.search
    },
    hasFilterFeature() {
      if(!this.listConfig) return false
      return !!this.listConfig.filter
    },
    searchPlaceholder() {
      const defaultPlaceholder = this.$t('action.keyword')
      if(!this.listConfig) return defaultPlaceholder
      if(typeof this.listConfig.search != 'string') return defaultPlaceholder
      return this.$t(this.listConfig.search)
    },
  },
  watch: {
    listFilter: {
      immediate: true,
      handler() {
        this.showFilter = !!this.listFilter
      },
    },
    searchKeyword: {
      immediate: true,
      handler() {
        this.keyword = this.searchKeyword
      },
    },
  },
  components: {
    listFilter: () => import('@/components/list/listSearchAndFilter/filter.vue'),
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>